export default function() {
    const dragDropInterfaces = document.querySelectorAll('.drag-and-drop')

    Array.from(dragDropInterfaces).forEach(dragDropInterface => {
        const inputElement = dragDropInterface.querySelector('input')
        inputElement.addEventListener('change', event => handleFiles(event, dragDropInterface), false)
    })
}

function handleFiles(event, dragDropInterface) {
    const file = event.target.files[0]
    const uploaded = dragDropInterface.querySelector('.uploaded')
    uploaded.innerText = file.name
}