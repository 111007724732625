import setupCvUpload from './cv-upload.js'

setupCvUpload()
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = window.csrfTokenValue;
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.csrfTokenValue;
}

/**
 * Load/Import Plugins
 * If you don't see a plugin below it might be autloaded via webpack
 * Check webpack.mix.js for autoloaded plugins.
 */
require('jquery-match-height');
require('slick-carousel');
// require('isotope-layout');
// require('imagesloaded');
// require('jquery-bridget');
import { WOW } from 'wowjs';
import * as Vue from 'vue';
import "@babel/polyfill";

/**
 * Import Foundation Components
 * check ./foundation.js if you need to enable more
 */
import { Foundation } from './foundation';
Foundation.addToJquery(jQuery);

const axios = window.axios;

/**
 * Application script
 */

// Initialize Foundation
$(document).foundation();

// Additional Logic
$(function() {

    // Gallery Carousel
    $('.carousel__slick').slick({
        autoplay: false,
        lazyLoad: true,
        setPosition: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        infinite: false,
        dots: false,
        prevArrow: '<img class="left-arrow" src="/img/Left_arrow.svg" alt="" />',
        nextArrow: '<img class="right-arrow" src="/img/Right_arrow.svg" alt="" />'
    });

    // Wowjs
    var wow = new WOW(
        {
            boxClass:     'wow',            // animated element css class (default is wow)
            animateClass: 'is-animating',   // animation css class (default is animated)
            offset:       40,                // distance to the element when triggering the animation (default is 0)
            mobile:       true,             // trigger animations on mobile devices (default is true)
            live:         true,             // act on asynchronously loaded content (default is true)
            callback:     function(box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
        }
    );
    wow.init();

    // Vue project filter app
    if(document.getElementById("projects-filter")){
        new Vue({
            el: '#projects-filter',
            delimiters: ['${', '}'],
            data : {
                projects: [],
                filter : 'all',
                nextPage: 1,
                totalPages: null,
                limit: 12,
                fetchError: false,
                showLoadMore: false,
                showAllButton: true,
                isLoading: true
            },
            methods : {

                /**
                 * Filters projects, resetting the data and fetching with new criteria.
                 * @author Josh Smith <josh.smith@platocreative.co.nz>
                 * @param  {object} e      Event object
                 * @param  {string} filter Category to filter projects on
                 * @return {object}        Promise
                 */
                filterProjects: function(e, filter){
                    e.preventDefault();

                    // Update the filter hash
                    window.location.hash = filter;

                    this.projects = [];
                    this.nextPage = 1;
                    this.totalPages = null;
                    this.filter = filter;
                    this.showAllButton = false;
                    // if (filter === "all"){
                    //     this.showAllButton = true;
                    // } else {
                    //     this.showAllButton = false;
                    // }
                    return this.fetchProjects({limit: this.limit, filter: this.filter, nextPage: this.nextPage}).then(() => {
                        Foundation.SmoothScroll.scrollToLoc('#filter-projects', {
                            threshold: 50,
                            offset: 0
                        });
                    });
                },

                /**
                 * Fetches projects from the server
                 * @author Josh Smith <josh.smith@platocreative.co.nz>
                 * @return {object} Promise
                 */
                fetchProjects : function({limit, filter, nextPage, offset}){

                    this.isLoading = true;
                    this.showAllButton = false;
                    // Fetch the next batch of projects, and render them.
                    return this.makeRequest({limit, filter, nextPage, offset}).then((response) => {

                        // Append new projects to the array, and keep the total number of pages in sync.
                        this.projects = this.projects.concat(response.data.data);
                        this.totalPages = response.data.meta.pagination.total_pages;

                        this.nextPage += 1;

                        // Hide the load more button if there's no more pages.
                        this.showAllButton = !(this.nextPage > this.totalPages);

                    }).catch((error) => {
                        console.log(error);
                    }).then(() => {
                        // Hide the loading spinner.
                        this.isLoading = false;
                    });
                },
                /**
                 * Internal utility method for performing the actual request
                 * @author Josh Smith <josh.smith@platocreative.co.nz>
                 * @return {object} Promise
                 */
                makeRequest : function({limit, filter, nextPage, offset}){

                    let queryParams = {}
                    if( limit ) queryParams['limit'] = limit
                    if( filter ) queryParams['categories'] = filter
                    if( nextPage ) queryParams['page'] = nextPage
                    if( offset ) queryParams['offset'] = offset

                    return axios.get(`/api/projects.json`, {params: queryParams});
                },

                /**
                 * Fetches projects from the server
                 * @author Josh Smith <josh.smith@platocreative.co.nz>
                 * @return {object} Promise
                 */
                fetchAllProjects : function(){
                    this.fetchProjects({offset: this.limit})
                    this.showAllButton = false
                },
            },

            /**
             * Lifecycle hook, fired when Vue app is initialised
             */
            created: function() {

                // Update the filter, if there's one set in the hash
                if( window.location.hash ){
                    this.filter = window.location.hash.replace(/#/, '');
                }

                // Load the initial set of projects, and scroll to the filter heading
                this.fetchProjects({limit: this.limit, filter: this.filter, nextPage: this.nextPage}).then(() => {

                    // Scroll to the filter
                    if( window.location.hash !== '' ){
                        Foundation.SmoothScroll.scrollToLoc('#filter-projects', {
                            threshold: 50,
                            offset: 0
                        });
                    }

                });
            },

            /**
             * Lifecycle hook, fired when the state is updated
             */
            updated: function(){
                 wow.sync();
            }
        });
    }
});
